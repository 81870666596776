<template>
    <div>
        <div
            style="display: flex;align-items:center;font-size: 20px;justify-content: center; width: 80%;margin: 4px auto;">
            <div>Start Time : {{ start }}</div>
            <div style="margin-left:26px">End Time : {{ end }}</div>
            <el-button style="margin-left:26px" @click="check" type="primary">Play</el-button>
        </div>
        <video class="myVideo" :src="videoUrl" ref="video"></video>
    </div>
</template>

<script>
import { recordPlay, checkPlayTime, getProjectInfoNew } from '../../api/index'
import { getUserId, getAccountCountry, getAccountContinent } from '../../utils/store'
import {
    formatDateUTC,
    formatDate
} from '../../utils/date'
export default {
    data() {
        return {
            timer: null,
            video: null,
            videoUrl: '',
            start: '',
            end: '',
            lecture_begin_time_utc: '',
            lecture_end_time_utc: '',
            account_continent: getAccountContinent(),
        }
    },
    mounted() {
        this.video = this.$refs.video
        this.getProject()
    },
    destroyed() {
        window.clearInterval(this.timer)
    },
    methods: {
        getProject() {
            getProjectInfoNew('2024WEC_International', getUserId()).then(res => {
                let result = res.data.lecture_time_arr
                let firstKey = Object.keys(result)[0]
                let lecture_begin_time = result[firstKey].lecture_begin_time//UAT+8时间 
                let lecture_end_time = result[firstKey].lecture_end_time //utc+8时间

                this.lecture_begin_time_utc = this.recalculateTimeByContinent(lecture_begin_time + 8*3600);//转换成本地时间
                this.lecture_end_time_utc = this.recalculateTimeByContinent(lecture_end_time + 8*3600);

                this.start = formatDate(new Date(lecture_begin_time * 1000), 'yyyy-MM-dd hh:mm')
                this.end = formatDate(new Date(lecture_end_time * 1000), 'yyyy-MM-dd hh:mm')

                this.videoUrl = result[firstKey].lecture_url

                // this.start = res.data.lecture_starttime
                // this.end = res.data.lecture_endtime
                // this.videoUrl = res.data.videoUrl
            })
        },
        getUTCTime(data) {
            var time = new Date(); //获取时间
            // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
            var dataOffset = new Date().getTimezoneOffset() * 60000
            // 获取格林威治时间 
            var utc = time.getTime() + dataOffset;  // 两个时间戳
            // 拿格林威治时间去反推指定地区时间    
            var newTime = utc + 3600000 * data;  // 
            var times = new Date(newTime);
            return times
        },
        recalculateTimeByContinent(time) {//TODO
            //input time is the start time on the server (UTC+8)
            // Convert input time to milliseconds
            const inputTime = time;//new Date(time).getTime();
            // Get user's local timezone offset in minutes
            const localOffset = new Date().getTimezoneOffset();

            // Define base offsets for different regions (in minutes)
            const asiaOceaniaOffset = - (8 * 60); // UTC+8
            const europeAfricaOffset = - 1 * 60; // UTC+1
            const americasOffset = +(4 * 60); // UTC-4

            let adjustedTime;

            if (this.account_continent === 'Asia' || this.account_continent === 'Oceania') {
                // Adjust from Asia/Oceania time to local time
                adjustedTime = inputTime + asiaOceaniaOffset * 60;
            } else if (this.account_continent === 'Europe' || this.account_continent === 'Africa') {
                // Adjust from Europe/Africa time to local time

                adjustedTime = inputTime + europeAfricaOffset * 60;
            } else if (this.account_continent === 'North America' || this.account_continent === 'South America') {
                // Adjust from Americas time to local time
                adjustedTime = inputTime + americasOffset * 60;
            } else {
                // If continent is not specified, assume the input time is already in local time
                adjustedTime = inputTime;
            }

            const formattedDate = new Date(adjustedTime * 1000).toLocaleString();
            console.log('adjustedTime A:', formattedDate);
            return Math.floor(adjustedTime);
        },
        check() {
            let current_time = parseInt(new Date().getTime() / 1000) //获取当前时区的时间

            if (current_time < this.lecture_begin_time_utc) {
                this.$message.warning('Not started yet')
                return
            }
            if (current_time > this.lecture_end_time_utc) {
                this.$message.warning('Ended')
                return
            }
            checkPlayTime({ user_id: getUserId(), project_code: '2024WEC_International' }).then(res => {
                if (res.data.code === 0) {
                    if (this.video.paused) {
                        this.video.currentTime = res.data.data
                        this.video.play()
                        this.startRecord()
                    }
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        startRecord() {
            this.timer = setInterval(() => {
                recordPlay({ user_id: getUserId(), watch_to_time: this.video.currentTime, project_code: '2024WEC_International' })
            }, 5000)
        },

    }
}
</script>

<style lang="scss" scoped>
.myVideo {
    width: 90%;
    height: 75vh;
    margin: 0 auto;
}
</style>